<template>
  <div>
    <v-row>
      <!-- <v-col cols="12" md="12" lg="9">
        <v-card flat>
          <v-card-title>
            Reporte por Junior
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :sort-by="['junior']"
            :headers="headers"
            :items="itemsRSenior"
            :search="search"
          >
            <template slot="body.append">
              <tr class="pink--text">
                <th colspan="5" class="title">Totales</th>
                <th class="title">{{ sumField("cantidad") }}</th>
                <th class="title">{{ sumField("precio") }}</th>
                <th class="title">{{ sumField("subtotal") }}</th>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col> -->
      <v-col cols="12" md="12" lg="12">
        <v-card flat>
          <v-card-title>
            Lista de Junior con Reportes

            <vue-excel-xlsx
              class="ml-5"
              :data="itemsRSeniorS"
              :columns="columns"
              :filename="'Reporte por Junior'"
              :sheetname="'sheetname'"
              style="background: #c1c1c1; padding: 2px 10px; border-radius: 5px"
            >
              Descargar
            </vue-excel-xlsx>

            <v-autocomplete
              :items="itemsCampana"
              item-text="name"
              item-value="id"
              class="ml-5 mt-5"
              label="Seleccionar Campaña"
              v-model="id_campana"
              @change="loadRSeniorS()"
            >
            </v-autocomplete>

            <v-switch
              class="ml-2"
              dense
              v-model="statusLock"
              label="Ordenar por Cerrados"
            ></v-switch>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchS"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-row>
            <v-col cols="12" md="4"> </v-col>
          </v-row>
          <v-data-table
            :headers="headersS"
            :items="statusLock ? itemsRSeniorSLock : itemsRSeniorS"
            :search="searchS"
            @click:row="handleClicks"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon class="mr-2 mx-auto" @click="loadRSeniorSS(item.id)">
                mdi-eye
              </v-icon>
              <v-icon
                class="mr-2 mx-auto"
                color="error"
                @click="deleteItem(item.id)"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:item.lock="{ item }">
              <v-icon
                class="mr-2 mx-auto"
                color="success"
                v-if="item.status == 1"
                @click="lockitem(item.id)"
              >
                mdi-lock-open
              </v-icon>
              <v-icon
                class="mr-2 mx-auto"
                v-if="item.status == 2"
                color="error"
                @click="unlockitem(item.id)"
              >
                mdi-lock
              </v-icon>
            </template>
            <template slot="body.append">
              <tr class="pink--text">
                <th colspan="2" class="title">Totales</th>

                <th colspan="1" class="title">{{ sumFieldSS("subtotal") }}</th>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="text-right mr-5" v-for="item of itemsSubtotal">
      <v-col
        ><b> {{ item.statusPedido }}:</b>
      </v-col>
      <v-col cols="1">
        <b>{{ item.subtotal_sta }}</b></v-col
      >
    </v-row>

    <v-dialog v-model="dialogobs" width="700">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-textarea
            ref="observacion"
            v-model="observacion"
            label="Observación"
          ></v-textarea>
          <v-btn color="primary" block @click="addObs()">
            ACTUALIZAR
          </v-btn>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="dialogobs = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogrs" width="80%">
      <v-card>
        <v-card-title>
          Lista de {{ senior }}
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchSS"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headersSS"
          :items="itemsRSeniorSS"
          :search="searchSS"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
              class="mr-2 mx-auto"
              @click="loadObs(item.id, item.observacion)"
            >
              mdi-pencil
            </v-icon>
          </template>

          <template slot="body.append">
            <tr class="pink--text">
              <th colspan="5" class="title">Totales</th>
              <th class="title">{{ sumFieldS("cantidad") }}</th>
              <th class="title">{{ sumFieldS("precio") }}</th>
              <th class="title">{{ sumFieldS("subtotal") }}</th>
            </tr>
          </template>
        </v-data-table>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogrs = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "RSenior",
  data: () => ({
    search: "",
    searchS: "",
    searchSS: "",
    senior: "",
    itemsRSenior: [],
    itemsRSeniorS: [],
    itemsRSeniorSLock: [],
    itemsRSeniorSS: [],
    itemsCampana: [],
    itemsSubtotal: [],
    id: "",
    id_campana: "",
    name: "",
    dialogrs: "",
    description: "",
    status: true,
    statusData: 1,
    statusLock: false,
    dialogobs: false,
    observacion: "",
    id_obs: "",
    id_junior: "",

    headers: [
      {
        text: "Junior",
        align: "start",

        value: "junior",
      },
      { text: "Catálogo", value: "catalogo" },
      { text: "Código", value: "codigo_producto" },
      { text: "Página", value: "pagina" },
      { text: "Producto", value: "producto" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Precio", value: "precio" },
      { text: "Sub Total", value: "subtotal" },
    ],

    headersS: [
      {
        text: "Junior",
        align: "start",
        value: "junior",
      },
      { text: "Senior", value: "senior" },
      { text: "SubTotal", value: "subtotal" },
      { text: "Acción", value: "actions" },
      { text: "Lock", value: "lock" },
    ],

    columns: [
      {
        label: "Junior",
        align: "start",
        field: "junior",
      },
      { label: "Senior", field: "senior" },
      { label: "SubTotal", field: "subtotal" },
    ],

    headersSS: [
      {
        text: "Junior",
        align: "start",
        value: "junior",
      },
      { text: "Catálogo", value: "catalogo" },
      { text: "Código", value: "codigo_producto" },
      { text: "Página", value: "pagina" },
      { text: "Producto", value: "producto" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Precio", value: "precio" },
      { text: "Observación", value: "observacion" },
      { text: "Sub Total", value: "subtotal" },
      { text: "Acción", value: "actions" },
    ],
  }),
  mounted() {
    this.loadCampanas();
    this.loadRSenior();
    //this.loadRSeniorS();
  },
  methods: {
    loadCampanas() {
      axios.get(process.env.VUE_APP_API_URL + "campana_all").then((result) => {
        this.itemsCampana = result.data;
      });
    },
    loadRSenior() {
      axios.get(process.env.VUE_APP_API_URL + "rep/senior").then((result) => {
        this.itemsRSenior = result.data;
      });
    },
    handleClick(row) {
      this.loadRSeniorSS(row.id);
    },

    loadRSeniorS() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "rep/campana/junior_with/" +
            this.id_campana
        )
        .then((result) => {
          this.itemsRSeniorS = result.data;
          this.loadRSeniorSLock();
          this.loadSubtotal();
        });
    },

    loadSubtotal() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "rep/campana/subtotalstatus/" +
            this.id_campana
        )
        .then((result) => {
          this.itemsSubtotal = result.data;
        });
    },

    loadRSeniorSLock() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "rep/campana/lock/junior_with/" +
            this.id_campana
        )
        .then((result) => {
          this.itemsRSeniorSLock = result.data;
        });
    },
    loadRSeniorSS(id) {
      this.id_junior = id;
      axios
        .get(process.env.VUE_APP_API_URL + "rep/junior/" + id)
        .then((result) => {
          this.dialogrs = true;
          this.senior = result.data[0].senior;
          this.itemsRSeniorSS = result.data;
        });
    },
    editItem(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "RSenior/" + id)
        .then((result) => {
          this.id = result.data[0].id;
          this.name = result.data[0].name;
          this.description = result.data[0].description;
          this.status = result.data[0].status;
          this.statusData = 2;
        });
    },
    addData() {
      let data = {
        name: this.name,
        description: this.description,
        status: this.status,
      };
      axios
        .post(process.env.VUE_APP_API_URL + "RSenior", data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Rubro registrado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.loadRSenior();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    updateData() {
      let data = {
        name: this.name,
        description: this.description,
        status: this.status,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "RSenior/" + this.id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Rubro actualizado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.cleanData();
            this.loadRSenior();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },

    loadObs(id, obs) {
      this.dialogobs = true;
      this.observacion = obs;
      this.$refs.observacion.$el.focus();
      this.id_obs = id;
    },

    addObs() {
      let data = {
        observacion: this.observacion,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "productos/obs/" + this.id_obs, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Observación actualizada con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.cleanObs();
            this.loadRSeniorSS(this.id_junior);
            this.dialogobs = false;
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },

    cleanObs() {
      //this.observacion = "";
    },

    lockitem(id) {
      let data = {
        status: 2,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "productos/lock/junior/" + id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            this.loadRSeniorS();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    unlockitem(id) {
      let data = {
        status: 1,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "productos/lock/junior/" + id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            this.loadRSeniorS();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    sumField(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsRSenior.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    sumFieldS(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsRSeniorSS.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    sumFieldSS(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsRSeniorS.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },
    deleteItem(id) {
      Swal.fire({
        title: "¿Está seguro de Eliminar?",
        text: "Una vez eliminada, la orden no se podrá recuperar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar",
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            title: "¿Está completamente seguro de Eliminar?",
            text: "Una vez eliminada, la orden NO se podrá recuperar",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "No, cancelar",
          }).then((result) => {
            if (result.value) {
              axios
                .delete(process.env.VUE_APP_API_URL + "productos/junior/" + id)
                .then((result) => {
                  console.log(result.data);
                  if (result.data.boolean == true) {
                    Swal.fire({
                      position: "center",
                      icon: "success",
                      title: "Orden eliminada con éxito",
                      showConfirmButton: true,
                      timer: 5000,
                    });
                    this.loadRSeniorS();
                  } else {
                    Swal.fire({
                      position: "center",
                      icon: "error",
                      title: "Oops...",
                      text: result.data.error.sqlMessage,
                      showConfirmButton: true,
                      timer: 8000,
                    });
                  }
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire("Cancelado", "La orden no fue eliminado", "error");
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelado", "La orden no fue eliminado", "error");
        }
      });
    },
    cleanData() {
      this.name = "";
      this.description = "";
      this.status = true;
      this.statusData = 1;
    },
  },
};
</script>
